<script>
export default {
    reasonForIssueList : [
        { value: null, text: '-- Please select  an option --'},
        { value: 'BDO issue', text: 'BDO issue'},
        { value: 'Float funding issue', text: 'Float funding issue'},
        { value: 'IndusInd issue', text: 'IndusInd issue'},
        { value: 'Internal system issue', text: 'Internal system issue'},
        { value: 'Marketing', text: 'Marketing'},
        { value: 'Notification processor issue', text: 'Notification processor issue'},
        { value: 'PSIGate issue', text: 'PSIGate issue'},
        { value: 'Other', text: 'Other'}
    ],
    currencyList : [
        { value: null , text: '-- Please select  an option --' },
        { value: 'CAD' , text: 'CAD' }
    ],
    promoTypeList : [
        { value: null, text: '-- Please select  an option --' },
        { value: 'True', text: 'Single Use'},
        { value: 'False', text: 'Shared'}
    ]
}
</script>