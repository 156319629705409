<template src='./viewpromotion.html'>
</template>
<style scoped src="./viewpromotion.css">
</style>
<script>
import promotionConstants from '@/constants/PromotionConstants.vue'
import { mapActions ,mapGetters } from 'vuex'
import Loading from 'vue-loading-overlay'
import { requiredIf } from 'vuelidate/lib/validators'
import appConstants from '@/constants/AppConstants'
import modal from '@/components/modal/notification/modal'
import jsonToExcel from 'vue-json-excel'
import pagination from '@/components/vtable/vtpagination.vue'

export default {
    data() {
        return {
            customerEmail : '',
            promoTypeList : promotionConstants.promoTypeList,
            promoType : '',
            isFirstTransation : '',
            allowGreaterThenFee : '',
            codePrefix : '',
            codeSuffix : '',
            toDate : '',
            fromDate : '',
            columns : ['PromoCode', 'PromoType', 'Name', 'FirstTransaction', 'AllowGreaterThanFee', 'Nomination', 'ExpirationDate', 'IssueTo', 'Action'],
            options : {
                headings : {
                    PromoCode           : 'Promo Code',
                    PromoType           : 'Promo Type',
                    Name                : 'Name',
                    FirstTransaction    : 'First Transaction',
                    AllowGreaterThanFee : 'AllowGreaterThanFee',
                    Nomination          : 'Nomination',
                    ExpirationDate      : 'Expiration Date',
                    IssueTo             : 'Issue To'
                }
            },
            loaderVisible : false,
            promoDetails : [],
            errorMessages : appConstants.formsMessages,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            fields: {
                'Promo Code': 'PromoCode',
                'Promo Type': 'PromoType',
                'Name': 'Name',
                'First Transaction': 'FirstTransaction',
                'Allow Greater than Fee': 'AllowGreaterThanFee',
                'Nomination': 'Nomination',
                'Expiration Date': 'ExpirationDate',
                'Issue to': 'IssueTo'
            },
            enableLazyLoading: appConstants.enableLazyLoading
        }
    },
    methods : {
        ...mapActions(['fetchPromoDetails']),
        async onSubmit() {
            try {
                if( this.fromDate !== "" ||  this.toDate !== ""){
                    this.$v.$touch()
                    if(this.$v.fromDate.$invalid || this.$v.toDate.$invalid){
                        return
                    }
                }
                this.loaderVisible = true
                await this.fetchPromoDetails({
                    'Code' : this.codePrefix,
                    'ExpirationDateFrom' : this.fromDate,
                    'ExpirationDateTo' : this.toDate,
                    'FirstTransactionOnly' : this.isFirstTransation,
                    'Issue' :  this.codeSuffix,
                    'IssuedTo' : this.customerEmail,
                    'PromoCodeType' : this.promoType,
                    'AllowGreaterThanFee' : this.allowGreaterThenFee
                })
                this.enableLazyLoading ? this.$refs.paginationRef.selectedPage = 1 : null      
                this.loaderVisible = false
            } catch (error) {
                this.alertObject = error
                this.$bvModal.show(this.$refs.childModal.id)
                this.loaderVisible = false                
            }
        },
        onClear() {
            this.customerEmail = ''
            this.promoType = ''
            this.isFirstTransation = ''
            this.allowGreaterThenFee = ''
            this.codePrefix = ''
            this.codeSuffix = ''
            this.toDate = ''
            this.fromDate = ''
            this.$v.$reset()
        },
        viewPromo (data) {
           let promo = data.split('-')
           this.promoDetails = this.getPromoDetails.find(s=> s.Code === promo[0] && s.Issue === promo[1])          
           this.$refs['my-modal'].show()
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        },
        exportTableData() {
            if(this.getPromoDetailsWithEX_DATE.length > 0) 
                return this.createPromoTableData(this.getPromoDetailsWithEX_DATE)
        },
        createPromoTableData(promoDetails) {
            let promoTableData = []
            promoDetails?.map(el => {
                promoTableData.push({   
                    'PromoCode'           : el.Code  + '-' + el.Issue,
                    'PromoType'           : el.IsIndividualCode === 'True' ? 'Single Use' : 'Shared' ,
                    'Name'                : el.Name,
                    'FirstTransaction'    : el.FirstTransactionOnly === 'True' ? 'Yes' : 'No',
                    'AllowGreaterThanFee' : el.AllowGreaterThanFee  === 'True'? 'Yes' : 'No',
                    'Nomination'          : el.Nomination,
                    'ExpirationDate'      : el.ExpirationDate,
                    'IssueTo'             : el.IssuedTo                     
                })                    
            })
            return promoTableData
        }

    },
    computed : {
        ...mapGetters(['getPromoDetails', 'getPromoDetailsWithEX_DATE']),
        paginationTotalRecords() {
            return this.getPromoDetailsWithEX_DATE ? (this.getPromoDetailsWithEX_DATE.length) : 0
        },
        getPromotionTableData() {
            let promoTableData = []
            if(this.getPromoDetailsWithEX_DATE.length > 0) {
                let promoDetails = this.getPromoDetailsWithEX_DATE
                if(this.enableLazyLoading) {
                    let selectedPage = this.$refs.paginationRef?.selectedPage
                    promoDetails = this.getPromoDetailsWithEX_DATE.slice((selectedPage - 1) * 10, selectedPage * 10)   
                }
                promoTableData = this.createPromoTableData(promoDetails)   
            }
            const updateTotalRecords = () => {
                if(this.$refs.paginationRef) {
                    this.$refs.paginationRef.totalRecords = this.getPromoDetailsWithEX_DATE?.length ?? 0
                }
            }
            this.enableLazyLoading ? updateTotalRecords() : null 
            return promoTableData
        }
    },
    components : {
        Loading, modal, jsonToExcel, pagination
    },
    validations: {
        fromDate: {
            required : requiredIf(function (){
                return this.fromDate!== '' || this.toDate !== ''    
            })
        },
        toDate: {
            required : requiredIf(function (){
                return this.fromDate!== '' || this.toDate !== ''    
            })
        },
    }
}
</script>